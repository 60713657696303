import './admin_license_style.css'
import React, { useEffect, useState, useRef, ChangeEvent } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { clientConfig } from '../../api/clientConfig';
import axios from 'axios';
import { Grid, GridColumn as Column, GridRowClickEvent, GridToolbar, GridEvent, GridHeaderCell, GridHeaderCellProps, GridFilterChangeEvent, GridNoRecords, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent, Switch } from "@progress/kendo-react-inputs";
import { getUserInfoFromStorage } from '../../utils/userInfoModule';
import { excelFileDownload, excelFileUpload } from '../../utils/excelFile';
import Popup from '../../Components/Popup/Popup';
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';

// 라이선스 종료일 오름차순 전역 선언
const initialSort: Array<SortDescriptor> = [
  // { field: "pdName", dir: "asc" },
  // { field: "ctPeriodFrom", dir: "asc" },
  { field: "ctPeriodTo", dir: "asc" },
];

// // 커스텀 테이블 헤더 정보 
// interface ProductNameHeaderProps extends GridHeaderCellProps {
//   children: React.ReactNode;
// }
// const ProductNameHeader = (props: ProductNameHeaderProps) => {
//   return (
//     <a className="k-link" onClick={props.onClick} style={{ backgroundColor: "#7D92A7" }} >
//       <span className="k-icon k-i-cart" />
//       <span style={{ color: "white" }}>{props.title}</span>
//       {props.children}
//     </a>
//   );
// };
// 헤더검색
// const filterData = (e: InputChangeEvent) => {
//   console.log(e);
//   let value = e.target.value as string;
//   console.log(value);
//   let filter: CompositeFilterDescriptor = {
//     logic: "or",
//     filters: [
//       { field: "uName", operator: "contains", value: value },
//     ],
//   };
//   setUserData(filterBy(userData, filter));
//   // setUserData((prevUserData) => filterBy([...prevUserData], filter));
// };
//   // 헤더셀 커스텀 (검색어입력)
//   const ProductNameHeader = (props: ProductNameHeaderProps) => {
//     return (
//       <Input
//         className='license-username-search-header'
//         placeholder={props.title}
//         // defaultValue={'이름'}
//         // onChange={() => console.log(props)}
//         onChange={filterData}
//         style={{
//         }}
//       />
//     );
//   };


// 제품 데이터 타입 정의
interface Product {
  totalContracts: string,
  totalActive: string,
  totalDeActive: string,
  expiredate: string,
  pdId: string,
  pdCode: string,
  pdName: string;
  pdVersion: string,
  pdFile: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdDescription: string,
  pdImage: string,
  dsName: string;
  pdUse: string;
}

// 유저 데이터 타입 정의
interface UserInfoData {
  uLoginId: string,
  uName: string,
  uEmail: string,
  uPhone: string,
  uAgree: string,
  uDept: string,
  uRank: string,
  uAdmin: string,
  uActive: string,
  uId: string,
}

// 계약 데이터 타입 정의
interface License {
  uId: string;
  pdId: string,
  pdCode: string,
  pdName: string;
  pdVersion: string,
  pdFile: string,
  pdProjectLicense: string,
  pdProjectProduct: string,
  pdType: string,
  pdIcon: string,
  pdTagLicense: string,
  pdDescription: string,
  pdImage: string,
  dsName: string;
  uLoginId: string,
  uName: string,
}

// DB 계약정보 타입
interface LicenseUser {
  uId: string;
  pdId: string;
  cId: string;
  uAdmin: string;
  puAdmin: string;
  ON_OFF: string;
  puEndDate: string;
  ctPeriodTo: string;
}

interface setSelectedUserLicense {
  pdName: string;
  ctPeriodFrom: string;
  ctPeriodTo: string;
}

interface dropDownType {
  cId: string,
  cCode: string,
  cName: string,
  cNumber: string,
  cPhone: string,
  cPost: string,
  cAddress: string,
  cFilePath: string,
  cFileName: string,
  cDescription: string,
}

interface UserInfo {
  uId: Number,
  uLoginId: string,
  uName: string,
  uEmail: string,
  uPhone: string,
  uAgree: string,
  cId: Number,
  uDept: string,
  uRank: string,
  uNumber: string,
  uAdmin: string,
  WorkerGuid: string,
  uRegDate: string,
}

const Admin_License = () => {

  // let menuOpen
  // console.log(menuOpen);
  // 솔루션 관리자용 회사정보 모음
  const [companyInfo, setCompanyInfo] = useState<Array<dropDownType>>([])
  // 솔루션 관리자용 회사cId
  const [selectedCompanyCId, setSelectedCompanyCId] = useState<string | null>(null);
  // 헤더에서 실행한걸 랜더링
  const [forceRerender, setForceRerender] = useState(false);
  // 헤더 선언값받기
  const handleRerender = () => {
    setForceRerender(prevState => !prevState);
  };

  // DB 제품정보 관리
  const [productData, setProductData] = useState<Array<Product>>([]);
  // DB 유저정보 관리
  const [userData, setUserData] = useState<Array<UserInfoData>>([]);
  // DB 유저의 계약정보 관리
  const [licenseUserData, setLicenseUserData] = useState<Array<LicenseUser>>([]);
  // DB 계약정보 관리
  const [licenseData, setLicenseData] = useState<Array<License>>([]);
  // 계약정보에서 선택한 유저의 제품번호 관리
  const [productId, setProductId] = useState('')
  // // 계약정보에서 선택한 유저의 제품 권한 관리
  // const [productAdmin, setProductAdmin] = useState('')
  // console.log(productId);
  const [btnShow, setBtnShow] = useState(false)
  // 세선스토리지에서 정보가져오기
  const userInfoObject = getUserInfoFromStorage();
  // 위젯 호출 버튼
  const headerRef = useRef<any>(null);
  // 현재 페이지 관리
  const [currentPage, setCurrentPage] = useState(1);
  // 페이지 호출
  const handlePageChange = (pageNumber: React.SetStateAction<number>) => {
    setCurrentPage(pageNumber);
  };
  // 선택한 유저 정보 관리
  const [seletedRowUserInfo, setSeletedRowUserInfo] = useState()
  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };

  // 검색드롭다운 관리
  const [selectedLicense, setSelecteLicense] = useState('::제조사::');
  // 검색드롭다운 내용 설정
  const LicenseOption = ['::제조사::', ...Array.from(new Set(licenseData?.map((row) => row.dsName)))] || [];
  // 검색드롭다운 데이터 설정
  const filteredlicenseData = licenseData
    ? licenseData.filter((row) => {
      return (
        Object.values(row).some((value) => {
          return value;
        }) &&
        (selectedLicense === '::제조사::' || row.dsName === selectedLicense)
      );
    })
    : [];
  // const [isSelectedCell, setIsSelectedCell] = useState(false)
  // console.log(console.log('isSelectedCell', isSelectedCell));
  // 라이선스 설정 선택 td 색상 강조 상태 관리 라이선스 설정의 데이터가 위에filteredlicenseData에서 가져와서 여기에있음 (rerendering 문제)
  const [isSelectedCellIndex, setIsSelectedCellIndex] = useState(-1)
  // console.log(console.log('isSelectedCellIndex', isSelectedCellIndex));

  // 라이선스 제조사 목록 선택 이벤트
  const clickDropDown = (event: DropDownListChangeEvent) => {
    // 목록생성
    setSelecteLicense(event.target.value)
    // 유저목록초기화
    setLicenseUserData([])
  }
  // 현재 날짜를 가져오는 함수
  const getCurrentDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // 현재시간 관리
  const [todayDate, setTodayDate] = useState<string>(getCurrentDate());

  // 날짜 계산 및 변환
  const calculateRemainingDays = (expiredate: string): number => {
    const today = new Date(todayDate);
    const expire = new Date(expiredate);
    const timeDiff = expire.getTime() - today.getTime();
    // milliseconds to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  // 솔루션관리자 드롭다운선택시 cId 변경
  // const handleDropDownChange = (event: DropDownListChangeEvent) => {
  //   const selectedCName = event.target.value;
  //   const selectedCompany = companyInfo.find(company => company.cName === selectedCName);

  //   if (selectedCompany) {
  //     // 세션 스토리지에서 유저 정보 가져오기
  //     const MasterInfo = getUserInfoFromStorage();
  //     console.log(MasterInfo);

  //     // userInfo가 null이 아니면 업데이트하고 세션 스토리지에 저장
  //     MasterInfo.cId = selectedCompany.cId;
  //     sessionStorage.setItem('MasterInfo', JSON.stringify(MasterInfo));

  //     setSelectedCompanyCId(selectedCompany.cId);
  //   }
  // };

  // 솔루션관리자 드롭다운선택시 cId 변경
  const handleComboBoxChange = (event: ComboBoxChangeEvent) => {
    const selectedCName = event.target.value;
    const selectedCompany = companyInfo.find(company => company.cName === selectedCName);

    if (selectedCompany) {
      // 세션 스토리지에서 유저 정보 가져오기
      const MasterInfo = getUserInfoFromStorage();
      console.log(MasterInfo);

      // userInfo가 null이 아니면 업데이트하고 세션 스토리지에 저장
      MasterInfo.cId = selectedCompany.cId;
      sessionStorage.setItem('MasterInfo', JSON.stringify(MasterInfo));

      setSelectedCompanyCId(selectedCompany.cId);
    }
  };


  // 솔루션 관리자용 회사 정보 모음
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getCompany`, {
        });
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setCompanyInfo(response.data);
          // console.log('getCompany : ', response.data);
        }
      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();
  }, []);



  // DB 장비정보 조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getAdminToUserProductInfo`, {
          // uId: userInfoObject.uId,
          cId: userInfoObject.cId,
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setProductData(response.data);
          // console.log(data);
        } else {
          setProductData([])
        }

      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();


  }, [userInfoObject.cId]);


  // DB 유저 정보조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getAdminToUserInfoData`, {
          cId: userInfoObject.cId,
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          // setUserData(response.data);
          // console.log('getAdminToUserInfoData :', response.data);
          // 조회용 아이디 안나오게 필터링 
          const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
          setUserData(DeletedViewData);
          // console.log('getAdminToUserInfoData__DeletedViewData :', DeletedViewData);

        } else {
          setUserData([])
        }

      } catch (error) {
        console.error('Error user_license fetchData:', error);
      }
    };

    fetchData();
    // console.log('실행');
  }, [userInfoObject.cId, forceRerender]);

  // DB 계약 정보조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getLicenseInfo`, {
          cId: userInfoObject.cId,
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setLicenseData(response.data);
          console.log('getLicenseInfo response.data : ', response.data);
        } else {
          setLicenseData([])
        }

      } catch (error) {
        console.error('Error getLicenseInfo fetchData:', error);
      }
    };

    fetchData();
  }, [userInfoObject.cId, forceRerender]);

  // DB 계약상태 조회
  useEffect(() => {

    const fetchData = async () => {
      try {

        // 서버로 cId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getLicenseState`, {
          cId: userInfoObject.cId,
          pdId: productId
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          // setLicenseUserData(response.data);
          console.log('getLicenseState : ', response.data);
          // 조회용 아이디 안나오게 필터링 
          const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
          setLicenseUserData(DeletedViewData);
          console.log('useEffect getLicenseState DeletedViewData :', DeletedViewData);

        } else {
          setLicenseUserData([])
        }

      } catch (error) {
        console.error('Error getLicenseInfo fetchData:', error);
      }
    };

    fetchData();
    // console.log('getLicenseInfo');
  }, [userInfoObject.cId, forceRerender]);

  // 스위치버튼으로 계정 활성 여부 요청
  const handleSwitchToggle = async (cId: string, uId: string, uActive: string) => {
    // console.log('handleSwitchToggle : ', cId, '/', uId, '/', uActive, '/',);
    try {
      const response = await axios.post(`${clientConfig.URL}/updateUserActiveStatus`, {
        cId: cId,
        uId: uId,
        uActive: uActive === 'Y' ? 'N' : 'Y',
      });
      if (response.data.success) {
        // 업데이트가 성공하면 상태를 업데이트
        const updatedData = userData.map(item => (item.uId === uId ? { ...item, uActive: response.data.updatedValue } : item));
        setUserData(updatedData);
      } else {
        console.error('서버에서 업데이트 실패:', response.data.message);
      }
    } catch (error) {
      console.error('서버 요청 실패:', error);
    }
  };

  // 스위치버튼으로 계정 권한 변경 요청
  const handleAdminToggle = async (cId: string, uId: string, uAdmin: string) => {
    // console.log('handleAdminToggle : ', cId, '/', uId, '/', uAdmin, '/',);
    try {
      const response = await axios.post(`${clientConfig.URL}/updateUserAdmin`, {
        cId: cId,
        uId: uId,
        uAdmin: uAdmin === '일반' ? '관리' : '일반',
      });
      if (response.data.success) {
        // 업데이트가 성공하면 상태를 업데이트
        const updatedData = userData.map(item => (item.uId === uId ? { ...item, uAdmin: response.data.updatedAdmin } : item));
        setUserData(updatedData);
      } else {
        console.error('서버에서 업데이트 실패:', response.data.message);
      }
    } catch (error) {
      console.error('서버 요청 실패:', error);
    }
  };

  // 스위치버튼으로 계정 라이선스 상태변경 요청
  const handleLicenseToggle = async (uId: string, pdId: string, cId: string, uAdmin: string, ON_OFF: string,) => {
    // console.log('uId, pdId, cId, uAdmin, ON_OFF : \n', uId, pdId, cId, uAdmin, ON_OFF);
    try {
      const response = await axios.post(`${clientConfig.URL}/updateUserLicenseState`, {
        uId,
        pdId,
        cId,
        uAdmin,
        ON_OFF: ON_OFF === 'ON' ? 'OFF' : 'ON'
      })
      if (response.data.success) {
        // 업데이트가 성공하면 상태를 업데이트
        // const updatedData = licenseUserData.map(item => (item.pdId === pdId ? { ...item, ON_OFF: ON_OFF === 'ON' ? 'OFF' : 'ON' } : item));
        // setLicenseUserData(updatedData);
        try {
          // licenseUserData 정보 업데이트
          const resUpdateState = await axios.post(`${clientConfig.URL}/getLicenseState`, {
            cId: userInfoObject.cId,
            pdId: productId
          });

          if (resUpdateState.data.length > 0) {
            // 서버에서 받아온 데이터를 상태로 설정
            // setLicenseUserData(resUpdateState.data);
            console.log('handleLicenseToggle getLicenseState : ', resUpdateState.data);
            // 조회용 아이디 안나오게 필터링 
            const DeletedViewData = resUpdateState.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
            setLicenseUserData(DeletedViewData);
            console.log('getAdminToUserInfoData__DeletedViewData :', DeletedViewData);
          }

        } catch (error) {
          console.error('Error getLicenseInfo fetchData:', error);
        }
        // console.log(response);
      } else {
        console.error('서버에서 업데이트 실패:', response.data.message);
      }
    } catch (error: any) {
      console.error('Error toggling license state:', error);
      if (error.response && error.response.status === 400) {
        setPopupMessage("최대 라이선스 허용입니다.");
        setShowPopup(true);
      } else {
        setPopupMessage("서버오류가 발생했습니다.");
        setShowPopup(true);
      }
    }
    try {

      // licenseData 정보 업데이트
      const response = await axios.post(`${clientConfig.URL}/getLicenseInfo`, {
        cId: userInfoObject.cId,
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        setLicenseData(response.data);
        // console.log('getLicenseInfo response.data : ',response.data);
      }

    } catch (error) {
      console.error('Error getLicenseInfo fetchData:', error);
    }
  };

  // 선택한 라이선스를 유저 전체 부여
  const handleBulkActivation = async () => {
    try {
      for (const item of licenseUserData) {
        await handleLicenseToggle(item.uId, productId, userInfoObject.cId, item.uAdmin, 'OFF');
        console.log('Switch activated for:', item.uId, productId, item.uAdmin, userInfoObject.cId);
      }
    } catch (error) {
      console.error('Error bulk activating licenses:', error);
    }
  };


  // 접속 해제 버튼 이벤트
  const handleConnectBtn = async (uId: string, productId: string) => {
    console.log('handleConnectBtn : ', uId, '/', productId, '/',);
    try {
      const response = await axios.post(`${clientConfig.URL}/disconnectUser`, {
        uId: uId,
        pdId: productId,
      });
      if (response.data.success) {
        // 업데이트가 성공하면 상태를 업데이트
        alert('접속 해제되었습니다.');
        //window.location.reload();
        setTimeout(() => {
          window.location.href = "/admin_license";
        }, 1000); // alert 후 일정 시간 후 이동
      } else {
        console.error('서버에서 업데이트 실패:', response.data.message);
      }
    } catch (error) {
      console.error('서버 요청 실패:', error);
    }
  };

  // // const handleBulkActivation = async () => {
  // const handleBulkActivation = async () => {
  //   try {
  //     // 라이선스 일괄 활성화를 위한 모든 Switch를 ON 상태로 변경
  //     // const updatedData = await Promise.all(
  //     // await Promise.all(
  //     console.log(licenseUserData);
  //     licenseUserData.map(async (item) => {
  //       // handleLicenseToggle 유저등록
  //       await handleLicenseToggle(item.uId, productId, userInfoObject.cId, item.uAdmin, 'OFF');
  //       setPopupMessage('라이선스 일괄 활성화 했습니다.')
  //       setShowPopup(true)
  //       // await handleLicenseToggle(item.uId, item.pdId, item.cId, item.uAdmin, 'ON');
  //       // 각 작업이 완료될 때마다 콘솔 로그 출력
  //       console.log('Switch activated for:', item.uId, productId, item.uAdmin, userInfoObject.cId);
  //     })
  //     // );

  //     // 모든 Switch가 ON으로 변경된 데이터를 상태로 설정
  //     // setLicenseUserData(updatedData);
  //   } catch (error) {
  //     console.error('Error bulk activating licenses:', error);
  //   }
  // };


  // 헤더의 위젯버튼호출위한 이벤트 버튼
  const handleClick = () => {
    setSeletedRowUserInfo(undefined)
    if (headerRef.current) {
      headerRef.current.toggleSideMenu();
    }
  };

  // 사용자 선택시 위젯창으로 유저정보 넘기기
  const handleGridRowClick = (event: GridRowClickEvent): void => {
    // 위젯창 열기
    handleClick();
    // 선택한 유저정보 값넘기기
    setSeletedRowUserInfo(event.dataItem)
    // console.log('event__handleGridRowClick : ', delete event.dataItem.uLoginPw,event.dataItem);
  }

  // 라이선스 선택시 유저정보 넘기기
  const handleLicense = async (event: GridRowClickEvent) => {
    // console.log('event_handleLicense', event)
    // setIsSelectedCell(true)
    // 버튼 보이게 하기
    setBtnShow(true)
    // console.log(event);
    // console.log(event.dataItem);
    // 선택한 제품번호 상태
    setProductId(event.dataItem.pdId);
    // 선택한 제품번호 상태
    // setProductAdmin(event.dataItem.pdAdmin);
    // console.log('licenseUserData : ', licenseUserData);
    try {
      // 서버로 cId를 보내기
      const response = await axios.post(`${clientConfig.URL}/getLicenseState`, {
        cId: userInfoObject.cId,
        pdId: event.dataItem.pdId
      });

      if (response.data.length > 0) {
        // 서버에서 받아온 데이터를 상태로 설정
        // setLicenseUserData(response.data);
        // console.log('handleLicense getLicenseState : ', response.data);
        // 조회용 아이디 안나오게 필터링 
        const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
        setLicenseUserData(DeletedViewData);
        // console.log('handleLicense getLicenseState DeletedViewData :', DeletedViewData);

      }

    } catch (error) {
      console.error('Error getLicenseInfo fetchData:', error);
    }
  }


  // 검색용 회사 이름 가공
  let companyName = companyInfo.map(company => company.cName).slice()
  // console.log('companyName : ', companyName);
  // 검색용 회사 이름 상태 관리
  const [data, setData] = useState(companyName);
  // console.log('datasetData : ', data);

  // 검색용 회사 이름 데이터 상태 관리
  const filterData = (filter: FilterDescriptor) => {
    const data = companyName;
    console.log(console.log('data1', data));
    return filterBy(data, filter);
  };
  // 검색용 회사 이름 검색시 이벤트 관리
  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    console.log(console.log('data2', data));
    setData(filterData(event.filter));
    console.log(console.log('data3', data));
  };
  // 검색용 데이터 선택시 보여줌
  const makeData = () => {
    setData(companyName)
  }

  // 각 페이지제목에 대한 컴포넌트들
  // 대시보드 제목
  const Page1Component = () => {
    return <h1>INNOSERVICE LICENSE MANAGMENT</h1>;
  };
  // 라이선스 관리(설정) 제목
  const Page2Component = () => {
    return <h1>INNOSERVICE LICENSE MANAGEMENT</h1>;
  };
  // 라이선스 조회 제목
  const Page21Component = () => {
    return <h1>INNOSERVICE LICENSE MANAGEMENT</h1>;
  };
  // 사용자 관리 제목
  const Page3Component = () => {
    return <h1>INNOSERVICE USER MANAGEMENT</h1>;
  };
  // // 개인 정보 수정 제목
  // const Page4Component = () => {
  //   return <h1>INNOSERVICE EDIT MY PROFILE</h1>;
  // };


  // 각 페이지내용에 대한 컴포넌트 내용들
  // 대시보드 내용
  const Page1BodyComponent = () => {
    // 정렬 상태 관리
    const [sort, setSort] = React.useState(initialSort);
    // 검색어 상태관리
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    // 검색어 입력란 변경이벤트 처리
    const handleSearchChange = (event: InputChangeEvent) => {
      setSearchKeyword(event.target.value as string);
    };
    //  검색어에 일치하는 데이터 필터링
    const filteredLicenseData = licenseData.filter((row) => {
      // const filteredData = masterContractData.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
      });
    });

    return <div className="single-item item">
      <div className="info">
        <h3>
          <a>대시보드</a>
        </h3>
        {/* 시작 대시보드*/}
        <Grid
          // data={filteredlicenseData}
          data={orderBy(filteredLicenseData, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}

          resizable={true}
          // onRowClick={handleLicense}
          style={{ height: '40vh' }}
        >
          <GridToolbar>
            검색 :
            <Input
              placeholder='검색할 내용을 입력해 주세요.'
              style={{ width: '50%' }}
              onChange={handleSearchChange}
            />
          </GridToolbar>

          {/* 데이터없을때 나오는 문구 */}
          <GridNoRecords>
            라이선스 선택해주세요.
          </GridNoRecords>
          <Column title='라이선스명' field='pdName'
            cell={(props) => {
              const pdName = props.dataItem.pdName
              return (
                <td style={{ textAlign: 'center' }} >
                  {pdName}<br />{props.dataItem.pdUse === 'N' && <a style={{ color: 'red' }}>(서비스지원중지)</a>}
                </td>
              );
            }} />
          <Column title='시작일' field='ctPeriodFrom'
            cell={(props) => {
              const ctPeriodFrom = props.dataItem.ctPeriodFrom
              return (
                <td style={{ textAlign: 'center' }} >
                  {ctPeriodFrom}
                </td>
              );
            }} />
          <Column title='종료일' field='ctPeriodTo'
            cell={(props) => {
              const ctPeriodTo = props.dataItem.ctPeriodTo
              return (
                <td style={{ textAlign: 'center' }} >
                  {ctPeriodTo}
                </td>
              );
            }} />
          {/* <Column title='라이선스 현황'
            cell={(props) => {
              const ctLicense = props.dataItem.ctLicense
              const LicenseCount = props.dataItem.LicenseCount
              return (
                <td style={{ textAlign: 'center' }} >
                  {LicenseCount} / <a style={{ color: 'steelblue' }}>{ctLicense}</a>
                </td>
              );
            }} /> */}
        </Grid>

        {/* 끝 대시보드*/}
        <div className="meta">
          <ul>
            <li>
              <i className="fas fa-calendar-alt" />
              {/* 2024년 1월 2일(현재) */}
              {getCurrentDate()}(현재)
            </li>
          </ul>
          <a
            onClick={() => handlePageChange(2)}
            className="btn btn-theme border btn-sm"
            data-animation="animated slideInUp"
          >
            라이선스 관리
          </a>
          {/* <a href="admin_license_management.html">라이선스 관리</a> */}
        </div>
      </div>
    </div>;
  };

  // 라이선스 관리(설정) 내용
  const Page2BodyComponent = () => {
    // 라이선스 제품 이름 정렬
    const sortedLicense: Array<SortDescriptor> = [
      { field: "pdName", dir: "asc" },
    ];
    // 정렬 상태 관리
    const [sortLicense, setSortLicense] = React.useState(sortedLicense);

    // 라이선스 설정 유저 이름 정렬
    const nameSort: Array<SortDescriptor> = [
      { field: "uName", dir: "asc" },
    ];
    const [sort, setSort] = React.useState(nameSort);
    // 검색어 상태관리
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    // 검색어 입력란 변경이벤트 처리
    const handleSearchChange = (event: InputChangeEvent) => {
      setSearchKeyword(event.target.value as string);
    };

    //  검색어에 일치하는 데이터 필터링
    const filteredLicenseUserData = licenseUserData.filter((row) => {
      // const filteredData = masterContractData.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
      });
    });

    // 라이선스 계약일 관리
    const [startContractDate, setStartContractDate] = useState<Date>(new Date());




    // 스위치버튼으로 계정 puAdmin 변경 요청
    const handleLicenseAdminToggle = async (cId: string, uId: string, pdId: string, puAdmin: string) => {
      console.log('handleLicenseAdminToggle : ', cId, '/', uId, '/', pdId, '/', puAdmin, '/');
      try {
        const response = await axios.post(`${clientConfig.URL}/updateLicenseUserAdmin`, {
          cId: cId,
          uId: uId,
          pdId: pdId,
          puAdmin: puAdmin === '일반' ? '관리' : '일반',
        });
        if (response.data.success) {
          // 업데이트가 성공하면 상태를 업데이트
          const updatedData = licenseUserData.map(item => ((item.uId === uId && item.cId === cId && item.pdId === pdId) ? { ...item, puAdmin: response.data.updatedpuAdmin } : item));
          setLicenseUserData(updatedData);
          console.log(updatedData);
        } else {
          console.error('서버에서 업데이트 실패:', response.data.message);
        }
      } catch (error) {
        console.error('서버 통신 오류:', error);
      }
      try {
        // 서버로 cId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getLicenseState`, {
          cId: userInfoObject.cId,
          pdId: productId
        });

        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          // setLicenseUserData(response.data);
          console.log(response.data);
          // 조회용 아이디 안나오게 필터링 
          const DeletedViewData = response.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
          setLicenseUserData(DeletedViewData);
          console.log('handleLicenseAdminToggle getLicenseState DeletedViewData :', DeletedViewData);
        }

      } catch (error) {
        console.error('Error getLicenseInfo fetchData:', error);
      }
    };


    return <div className="single-item item">
      <div className="info">
        <div className="contact-form">
          <div className="admin_license_list">
            <h2>라이선스 설정</h2>
            <div className="user_contact_list_bt_pack">
              <div className="user_contact_list_bt_margin">
                {/* 유저선택하면 보여주기 */}
                {btnShow && (
                  <a
                    style={{ cursor: 'pointer' }}
                    className="btn btn-theme border btn-sm"
                    data-animation="animated slideInUp"
                    onClick={handleBulkActivation}
                  >
                    라이선스 일괄 활성
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="meta">
          <div className="admin_license_list_area">
            <div className="admin_license_list_left_pack">
              <div className="admin_license_list_pack_contents">
                {/* <Grid data={licenseData} resizable={true} onRowClick={handleLicense} > */}
                {/* {filteredData? ()} */}
                <Grid
                  className='admin_license_list_table'
                  // style={{ width: '100%' }}
                  // data={filteredlicenseData}
                  data={orderBy(filteredlicenseData, sortLicense)}
                  resizable={true}
                  onRowClick={handleLicense}
                  sortable={true}
                  sort={sortLicense}
                  onSortChange={(e: GridSortChangeEvent) => {
                    setSortLicense(e.sort);
                  }}
                // style={{ height: '40vh' }}
                >
                  {/* 데이터없을때 나오는 문구 */}
                  <GridNoRecords>
                    라이선스 선택해주세요.
                  </GridNoRecords>
                  <GridToolbar>
                    <DropDownList
                      // style={{ width: '100%' }}
                      data={LicenseOption}
                      value={selectedLicense}
                      // onChange={(event) => setSelectedBuilding(event.target.value)}
                      onChange={(event) => clickDropDown(event)}
                    // onPageChange={(event)=>{console.log('eee',event)}}
                    />
                  </GridToolbar>
                  {/* <Column field='dsName' /> */}
                  <Column title='라이선스명' field='pdName'
                    cell={(props) => {
                      const pdName = props.dataItem.pdName
                      const ctLicense = props.dataItem.ctLicense
                      const LicenseCount = props.dataItem.LicenseCount
                      // console.log(props.dataIndex);

                      return (
                        // <td style={{ textAlign: 'center' }} >
                        // 선택한 td 색상 강조
                        <td key={props.dataIndex} onClick={() => setIsSelectedCellIndex(props.dataIndex)}
                          // style={{ textAlign: 'center', backgroundColor: isSelectedCell ? '#1274ac77' : 'inherit' }}>
                          style={{ textAlign: 'center', backgroundColor: isSelectedCellIndex === props.dataIndex ? '#1274ac77' : 'inherit' }}>
                          {pdName} ({LicenseCount}/<a style={{ color: 'steelblue' }}>{ctLicense}</a>)<br />{props.dataItem.pdUse === 'N' && <a style={{ color: 'red' }}>(서비스지원중지)</a>}
                        </td>
                      );
                    }} />
                </Grid>
              </div>
            </div>
            <div className="admin_license_list_right_pack">
              <div className="admin_license_list_pack_contents">
                {/* <Grid data={licenseUserData} resizable={true} scrollable='none' > */}
                <Grid
                  // data={licenseUserData} 
                  data={orderBy(filteredLicenseUserData, sort)}
                  resizable={true}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                  }}
                  style={{ height: '100vh' }}
                >
                  <GridToolbar>
                    검색 :
                    <Input
                      placeholder='검색할 내용을 입력해 주세요.'
                      style={{ width: '50%' }}
                      onChange={handleSearchChange}
                    />
                  </GridToolbar>
                  {/* 데이터없을때 나오는 문구 */}
                  <GridNoRecords>
                    일치한 내용이 없습니다.
                  </GridNoRecords>
                  <Column title="라이선스 사용자 설정"
                    cell={(props) => {

                      const uName = props.dataItem.uName;
                      const uLoginId = props.dataItem.uLoginId;
                      const isuAdmin = props.dataItem.puAdmin === '관리';
                      const ON_OFF = props.dataItem.ON_OFF;
                      const ctPeriodTo = props.dataItem.ctPeriodTo;
                      const puEndDate = props.dataItem.puEndDate;

                      return (
                        <div className="admin_license_ren border_pack">


                          {/* 한줄 라인 시작 */}
                          <div className="admin_license_ren_main_contents">
                            <div className="admin_license_ren_main_contents_half">
                              <div className="admin_license_ren_main_contents_control_title">
                                권한
                              </div>
                              <div className="admin_license_ren_main_contents_control_switch">
                                <Switch onLabel={"관리"} offLabel={"일반"} checked={isuAdmin}
                                  // 라이선스 활성안되있으면 클릭불가
                                  disabled={!props.dataItem.puAdmin}
                                  onChange={() => handleLicenseAdminToggle(
                                    props.dataItem.cId,
                                    props.dataItem.uId,
                                    productId,
                                    props.dataItem.puAdmin)} />
                              </div>
                            </div>
                            <div className="admin_license_ren_main_contents_half">
                              <div className="admin_license_ren_main_contents_control_title">
                                라이선스
                              </div>
                              <div className="admin_license_ren_main_contents_control_switch">
                                <Switch checked={ON_OFF === 'ON'}
                                  onChange={() => {
                                    handleLicenseToggle(
                                      props.dataItem.uId,
                                      productId,
                                      userInfoObject.cId,
                                      props.dataItem.uAdmin,
                                      ON_OFF);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="admin_license_ren_main_contents_half">
                              <div className="admin_license_ren_main_contents_control_title">
                                접속상태
                              </div>
                              <div className="admin_license_ren_main_contents_control_switch">
                              {(props.dataItem.CCU > 0) &&( 
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    className="contractGrid_top_toolbar_bt"
                                    data-animation="animated slideInUp"
                                    onClick={() => handleConnectBtn(props.dataItem.uId, productId)}
                                    //onClick={() => alert(props.dataItem.uId + "/" + productId)}
                                  >
                                    접속해제
                                  </a>
                                  )}
                              </div>
                            </div>
                          </div>
                          {/* 한줄 라인 끝 */}
                          {/* 한줄 라인 시작 */}
                          <div className="admin_license_ren_main_contents_types">
                            <div className="admin_license_ren_main_contents_second_pack">
                              <div className="admin_license_ren_main_contents_second_title">
                                기간설정
                              </div>
                              <div className="admin_license_ren_main_contents_second_data">
                                {/* {puEndDate} */}
                                <DatePicker className='license_datepicker'
                                  defaultValue={puEndDate ? new Date(puEndDate) : new Date(ctPeriodTo)}
                                  // defaultValue={new Date(puEndDate)}
                                  // placeholder={Date()}
                                  // value={startContractDate}
                                  // value={new Date(puEndDate)}
                                  // defaultValue={puEndDate ? new Date(puEndDate) : new Date(startContractDate)}
                                  // placeholder={new Date().toLocaleDateString()}

                                  format="yyyy.MM.dd"
                                  // 최소시작일 = 오늘날짜 
                                  min={new Date()}
                                  // 최대마감일 = 계약날짜
                                  max={new Date(ctPeriodTo)}
                                  onChange={async (event: DatePickerChangeEvent) => {
                                    const selectedDate = event.target.value;
                                    const date = new Date(selectedDate as Date);
                                    // 한국씩 날짜변화 
                                    const koreanTimeOffset = 9 * 60;
                                    const utcDate = new Date(date.getTime() + koreanTimeOffset * 60 * 1000);
                                    // 날짜형식변화 YYYY-MM-DD
                                    const formattedDate = utcDate.toISOString().split('T')[0];
                                    // 현재날짜
                                    const currentDate = new Date();
                                    const ctPeriodToDate = new Date(props.dataItem.ctPeriodTo);
                                    // 달력의 데이터로만 입력받게 설정
                                    if (event.syntheticEvent.type != 'click') {
                                      setPopupMessage('달력을 선택하여 입력해주세요.');
                                      setShowPopup(true);
                                      return
                                    }
                                    if (props.dataItem.ON_OFF === 'OFF') {
                                      setPopupMessage('라이선스가 없습니다.');
                                      setShowPopup(true);
                                      return;
                                    } else if (selectedDate) {
                                      // 오늘 날짜와 같거나 크고 계약날짜와 같거나 작아야함
                                      if (utcDate >= currentDate && utcDate <= ctPeriodToDate) {
                                        try {
                                          const updateResponse = await axios.post(`${clientConfig.URL}/updatePuEndDate`, {
                                            uId: props.dataItem.uId,
                                            pdId: productId,
                                            cId: props.dataItem.cId,
                                            puEndDate: formattedDate,
                                          });
                                  
                                          if (updateResponse.status === 200) {
                                            const licenseResponse = await axios.post(`${clientConfig.URL}/getLicenseState`, {
                                              cId: userInfoObject.cId,
                                              pdId: productId,
                                            });
                                  
                                            if (licenseResponse.data.length > 0) {
                                              setLicenseUserData(licenseResponse.data);
                                              console.log(licenseResponse.data);
                                  
                                              const DeletedViewData = licenseResponse.data.filter((item: { uLoginId: string }) => !item.uLoginId.includes(`view${userInfoObject.cId}@innoa.co.kr`));
                                              setLicenseUserData(DeletedViewData);
                                              setPopupMessage('라이선스 기간 설정 완료.');
                                              setShowPopup(true);
                                              // console.log('handleLicenseAdminToggle getLicenseState DeletedViewData :', DeletedViewData);
                                            }
                                          } else {
                                            console.error('Failed to update puEndDate:', updateResponse.statusText);
                                          }
                                        } catch (error) {
                                          console.error('Error updating puEndDate or fetching license state:', error);
                                        }
                                      } else {
                                        setPopupMessage('선택한 날짜가 유효 기간 내에 있지 않습니다.');
                                        setShowPopup(true);
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="admin_license_ren_main_contents_second_pack">
                              <div className="admin_license_ren_main_contents_second_title">이름</div>
                              <div className="admin_license_ren_main_contents_second_data">
                                {uName}
                              </div>
                            </div>
                            <div className="admin_license_ren_main_contents_second_pack">
                              <div className="admin_license_ren_main_contents_second_title">ID</div>
                              <div className="admin_license_ren_main_contents_second_data">
                                {uLoginId}
                              </div>
                            </div>
                          </div>
                          {/* 한줄 라인 끝 */}

                        </div>
                      );
                    }} />

                  {/* <Column field='uName' title='이름' />
                  <Column field='uLoginId' title='ID' />
                  <Column title='권한' field='puAdmin'
                    cell={(props) => {
                      const isuAdmin = props.dataItem.puAdmin === '관리';
                      return (
                        <td style={{ textAlign: 'center' }}>
                          <Switch onLabel={"관리"} offLabel={"일반"} checked={isuAdmin}
                            // 라이선스 활성안되있으면 클릭불가
                            disabled={!props.dataItem.puAdmin}
                            onChange={() => handleLicenseAdminToggle(
                              props.dataItem.cId,
                              props.dataItem.uId,
                              productId,
                              props.dataItem.puAdmin)} />
                        </td>
                      );
                    }} />
                  <Column title='라이선스 활성' field='ON_OFF'
                    cell={(props) => {
                      const ON_OFF = props.dataItem.ON_OFF;
                      return (
                        <td style={{ textAlign: 'center' }}>
                          <Switch checked={ON_OFF === 'ON'}
                            onChange={() => {
                              handleLicenseToggle(
                                props.dataItem.uId,
                                productId,
                                userInfoObject.cId,
                                props.dataItem.uAdmin,
                                ON_OFF);
                            }}
                          />
                        </td>
                      );
                    }} />
                  <Column title='기간설정' field='puEndDate'
                    cell={(props) => {
                      const puEndDate = props.dataItem.puEndDate;
                      const ctPeriodTo = props.dataItem.ctPeriodTo;
                      return (
                        <td style={{ textAlign: 'center' }}>
                          <DatePicker className='license_datepicker'
                            format="yyyy.MM.dd"
                            placeholder={Date}
                            // 최소시작일 = 오늘날짜 
                            min={new Date()}
                            // 최대마감일 = 계약날짜
                            max={new Date(ctPeriodTo)}
                          />
                        </td>
                      );
                    }} /> */}

                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  };

  // 라이선스 조회 내용
  const Page21BodyComponent = () => {


    // 선택한 유저의 라이선스 정보 관리
    const [seletedUserLicenseData, setSeletedUserLicenseData] = useState<Array<setSelectedUserLicense>>([]);
    // 정렬 상태 관리
    const [sort, setSort] = React.useState(initialSort);
    // 라이선스 조회 유저 이름 정렬
    const nameSort: Array<SortDescriptor> = [
      { field: "uName", dir: "asc" },
    ];
    // 라이선스 조회 유저 이름 정렬 상태 관리
    const [sortBySelectedUser, setSortBySelectedUser] = React.useState(nameSort);

    // 유저의 라이선스 검색어 상태 관리
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    // 유저의 라이선스 검색어 상태 관리
    const [searchKeyword2, setSearchKeyword2] = useState<string>('');

    // 유저의 라이선스 검색어 입력란의 값이 변경 이벤트
    const handleSearchChange = (event: InputChangeEvent) => {
      setSearchKeyword(event.target.value as string);
    };

    // 유저의 라이선스 검색어 입력란의 값이 변경 이벤트
    const handleSearchChange2 = (event: InputChangeEvent) => {
      setSearchKeyword2(event.target.value as string);
    };

    // 유저의 라이선스 검색어에 일치하는 데이터 필터링
    const filteredData = userData
      ? userData.filter((row) => {
        return (
          Object.values(row).some((value) => {
            return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
          })
        );
      })
      : [];

    //  검색어에 일치하는 데이터 필터링
    const filteredData2 = seletedUserLicenseData.filter((row) => {
      // const filteredData = masterContractData.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(searchKeyword2.toLowerCase());
      });
    });
    // 라이선스 조회 선택 td 색상 강조 상태 관리
    const [isSelectedCellIndex2, setIsSelectedCellIndex2] = useState(-1)
    // console.log(console.log('isSelectedCellIndex2', isSelectedCellIndex2));

    // 유저의 라이선스 목록 선택 이벤트
    const handleUserLicenseInfo = async (event: GridRowClickEvent) => {
      // 선택 전 정보초기화
      setSeletedUserLicenseData([]);
      // console.log(event);
      // console.log(event.dataItem);
      // console.log(event.dataItem.uId);
      // console.log(event.dataItem.cId);
      try {
        // 서버로 cId와 uId를 보내기
        const response = await axios.post(`${clientConfig.URL}/getSeletedUserLicense`, {
          uId: event.dataItem.uId,
          cId: event.dataItem.cId,
        });
        if (response.data.length > 0) {
          // 서버에서 받아온 데이터를 상태로 설정
          setSeletedUserLicenseData(response.data);
          console.log('getSeletedUserLicense :', response.data);
        }
      } catch (error) {
        console.error('Error getSeletedUserLicense fetchData:', error);
      }
    }

    return <div className="single-item item">
      <div className="info">
        <div className="contact-form">
          <div className="admin_license_list">
            <h2>라이선스 조회</h2>
            <div className="user_contact_list_bt_pack">
              <div className="user_contact_list_bt_margin">
              </div>
            </div>
          </div>
        </div>
        <div className="meta">
          <div className="admin_license_list_area">
            <div className="admin_license_list_left_pack">
              <div className="admin_license_list_pack_contents">
                <Grid
                  className='admin_license_list_table'
                  // data={filteredData}
                  resizable={true}
                  onRowClick={handleUserLicenseInfo}
                  // scrollable='virtual'
                  data={orderBy(filteredData, sortBySelectedUser)}
                  sortable={true}
                  sort={sortBySelectedUser}
                  onSortChange={(e: GridSortChangeEvent) => {
                    setSortBySelectedUser(e.sort);
                  }}
                  style={{ height: '40vh' }}
                >
                  <GridToolbar>
                    <Input
                      placeholder='검색할 내용을 입력해 주세요.'
                      onChange={handleSearchChange}
                      onClick={() => setSeletedUserLicenseData([])}
                      style={{ width: '100%' }} />
                  </GridToolbar>
                  {/* 데이터없을때 나오는 문구 */}
                  <GridNoRecords>
                    <div style={{ height: '100vh' }}>
                      정확한 성함을 입력해주세요.
                    </div>
                  </GridNoRecords>
                  {/* <Column field='uName' title='이름' headerCell={ProductNameHeader} /> */}
                  {/* <Column field='uName' title='이름' /> */}
                  <Column title='이름' field='uName'
                    cell={(props) => {
                      const uName = props.dataItem.uName;
                      // 조회용 아이디 감추기
                      const viewdHide = props.dataItem.uLoginId === `view${userInfoObject?.cId}@innoa.co.kr`;
                      return viewdHide ? null : (
                        // <td style={{ textAlign: 'center', }} >
                        <td key={props.dataIndex} onClick={() => setIsSelectedCellIndex2(props.dataIndex)}
                          style={{ textAlign: 'center', backgroundColor: isSelectedCellIndex2 === props.dataIndex ? '#1274ac77' : 'inherit' }}>
                          {uName}
                        </td>
                      );
                    }} />
                </Grid>
              </div>
            </div>
            <div className="admin_license_list_right_pack">
              <div className="admin_license_list_pack_contents">
                {/* 선택한 유저의 라이선스 목록 */}
                <Grid
                  // data={seletedUserLicenseData} 
                  // data={orderBy(seletedUserLicenseData, sort)}
                  data={orderBy(filteredData2, sort)}
                  resizable={true}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                    setSort(e.sort);
                  }}
                  style={{ height: '40vh', textAlignLast: 'center', }}
                >
                  <GridToolbar>
                    <Input
                      placeholder='검색'
                      onChange={handleSearchChange2}
                      // onClick={() => setSeletedUserLicenseData([])}
                      style={{ width: '100%', textAlignLast: 'left' }} />
                  </GridToolbar>

                  {/* 데이터없을때 나오는 문구 */}
                  <GridNoRecords>
                    사용자를 선택해주세요.
                  </GridNoRecords>
                  <Column field='pdName' title='제품명'
                    cell={(props) => {
                      const pdName = props.dataItem.pdName;
                      return (
                        <td>
                          {pdName}<br />{props.dataItem.pdUse === 'N' && <a style={{ color: 'red' }}>(서비스지원중지)</a>}
                        </td>
                      )
                    }} />
                  <Column field='ctPeriodTo' title='계약종료일' />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  };

  // 사용자 관리 내용
  const Page3BodyComponent = () => {
    // 라이선스 설정 유저 이름 정렬
    const nameSort: Array<SortDescriptor> = [
      { field: "uName", dir: "asc" },
    ];
    // 정렬 상태 관리
    // const [sort, setSort] = React.useState(Array<SortDescriptor>);
    const [sort, setSort] = React.useState(nameSort);
    // 검색어 상태관리
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    // 검색어 입력란 변경이벤트 처리
    const handleSearchChange = (event: InputChangeEvent) => {
      setSearchKeyword(event.target.value as string);
    };

    //  검색어에 일치하는 데이터 필터링
    const filteredUserData = userData.filter((row) => {
      // const filteredData = masterContractData.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(searchKeyword.toLowerCase());
      });
    });


    // menuOpen = true
    // console.log(menuOpen);
    return <div className="single-item item">
      <div className="info">
        <div className="contact-form">
          <div className="user_contact_list">
            <h2>사용자 리스트</h2>
            <div className="user_contact_list_bt_pack">
              <div className="user_contact_list_bt_margin">
                <a
                  style={{ cursor: 'pointer' }}
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={handleClick}
                >
                  사용자 등록
                </a>
              </div>
              {/* <div className="user_contact_list_bt_margin">
                <button
                  // style={{ cursor: 'pointer' }}
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  // onClick={() => {}}
                  // onClick={() => handleToggleSideMenu()}
                  onClick={handleClick} // 수정된 부분
                >
                  사용자 수정
                </button>
              </div> */}
            </div>
          </div>
          {/* <p>
                라이선스를 이용할 사용자 정보를 기입하여 등록합니다
            </p> */}
        </div>
        <div className="meta">
          <div className="contact_user_list">
            <Grid
              // data={userData}
              data={orderBy(filteredUserData, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              resizable={true}
              onRowClick={handleGridRowClick}
            >
              <GridToolbar>
                검색 :
                <Input
                  placeholder='검색할 내용을 입력해 주세요.'
                  style={{ width: '50%' }}
                  onChange={handleSearchChange}
                />
              </GridToolbar>
              {/* 데이터없을때 나오는 문구 */}
              <GridNoRecords>
                <div style={{ height: '100vh' }}>
                  사용자를 등록해주세요.
                </div>
              </GridNoRecords>
              {/* <Column field='uLoginId' title='ID' editable={true} editor="text" /> */}
              {/* <Column field='uEmail' title='Email' editable={true} editor="text" /> */}
              <Column title='ID' field='uLoginId'
                cell={(props) => {
                  const uLoginId = props.dataItem.uLoginId;
                  // 조회용 아이디 감추기
                  const viewdHide = uLoginId === `view${userInfoObject?.cId}@innoa.co.kr`;
                  return viewdHide ? null : (
                    <td style={{ textAlign: 'center' }}>
                      {uLoginId}
                    </td>
                  );
                }} />
              <Column title='이름' field='uName'
                cell={(props) => {
                  const uName = props.dataItem.uName;
                  const viewdHide = props.dataItem.uLoginId === `view${userInfoObject?.cId}@innoa.co.kr`;

                  return viewdHide ? null : (
                    <td style={{ textAlign: 'center' }}>
                      {uName}
                    </td>

                  );
                }} />
              <Column title='권한' field='uAdmin'
                cell={(props) => {
                  const uAdmin = props.dataItem.uAdmin;
                  const isuAdmin = uAdmin === '관리';
                  // 조회용 아이디 감추기
                  const viewdHide = props.dataItem.uLoginId === `view${userInfoObject?.cId}@innoa.co.kr`;
                  return viewdHide ? null : (
                    <td style={{ textAlign: 'center' }}>
                      {/* <Switch checked={isuAdmin} /> */}
                      <Switch onLabel={"관리"} offLabel={"일반"} checked={isuAdmin} onChange={() => handleAdminToggle(props.dataItem.cId, props.dataItem.uId, uAdmin)} />
                      {/* <Switch checked={isActive} onChange={() => handleSwitchToggle(props)} /> */}
                    </td>
                  );
                }} />
              <Column title='계정 활성' field='uActive'
                cell={(props) => {
                  const uActive = props.dataItem.uActive;
                  const isActive = uActive === 'Y';
                  // 조회용 아이디 감추기
                  const viewdHide = props.dataItem.uLoginId === `view${userInfoObject?.cId}@innoa.co.kr`;
                  return viewdHide ? null : (
                    <td style={{ textAlign: 'center' }}>
                      {/* <Switch checked={isActive} /> */}
                      <Switch checked={isActive} onChange={() => handleSwitchToggle(props.dataItem.cId, props.dataItem.uId, uActive)} />
                    </td>
                  );
                }} />
            </Grid>
          </div>
        </div>
      </div><div className="info">
        <div className="contact-form">
          <div className="contact_bulk_form">
            <h2>사용자 대량등록</h2>
            <div className="user_contact_list_bt_pack">
              <a
                // style={{ cursor: 'pointer' }}
                className="btn btn-theme border btn-sm"
                data-animation="animated slideInUp"
                onClick={() => excelFileDownload()}
              >
                양식 다운로드
              </a>
            </div>
          </div>
          <p>
            엑셀 양식을 다운 받아 로컬에서 작업후 다수의 사용자 정보를
            한번에 업로드 합니다.
          </p>
          {/* <form
            action="영교님이하시면되요"
            method="POST"
            className="contact-form"
          > */}
          <div className="file-upload">
            <input
              type="text"
              className="upload-name"
              // defaultValue={"엑셀파일을 선택해주세요"}
              // disabled 
              value={"엑셀파일을 선택해주세요"}
              onClick={handleClick}
              readOnly
            />
            <label className="bulk_upload_BT" onClick={handleClick} aria-disabled >
              파일 선택
            </label>
            <a
              // type="file"
              id="ex_filename"
              className="upload-hidden"
              onClick={handleClick}
            />
          </div>
          {/* Alert Message */}
          <div className="col-md-12 alert-notification">
            <div id="message" className="alert-msg" />
          </div>
          {/* </form> */}
        </div>
        <div className="meta">
          <ul>
            {/* 라이선스 만료 날짜를 표기 하기 위한 단순 오늘 날짜 뷰 */}
            {/* <li><i class="fas fa-calendar-alt"></i>현재 날짜 : 2024년 3월 19일</li> */}
          </ul>
          <a
            // style={{ cursor: 'pointer' }}
            className="btn btn-theme border btn-sm"
            data-animation="animated slideInUp"
            onClick={handleClick}
          >
            사용자 엑셀 정보 업로드 <i className="fa fa-paper-plane" />
          </a>
          {/* <a href="admin_license_management.html"></a> */}
        </div>
      </div>
    </div>;
  };

  return (
    <div>
      <Header seletedRowUserInfo={seletedRowUserInfo} ref={headerRef} onRerender={handleRerender} handlePageChange={currentPage} />
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      {/* Start Breadcrumb     ============================================= */}
      <div
        className="breadcrumb-area shadow dark bg-fixed text-center text-light"
        style={{ backgroundImage: "url(assets/img/2440x1578.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {/* <h1>InnoService License Managment</h1> */}
              {currentPage === 1 && <Page1Component />}
              {currentPage === 2 && <Page2Component />}
              {currentPage === 21 && <Page21Component />}
              {currentPage === 3 && <Page3Component />}
              {/* {currentPage === 4 && <Page4Component />} */}
              <ul className="breadcrumb">
                <li>
                  <a style={{ cursor: 'pointer' }}>
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">License Managment</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start License Management
    ============================================= */}
      <div className="blog-area full-blog left-sidebar full-blog default-padding">
        <div className="container">
          <div className="row">
            <div className="blog-items">
              <div className="blog-content col-md-9">
                {/* Single Item */}
                {currentPage === 1 && <Page1BodyComponent />}
                {currentPage === 2 && <Page2BodyComponent />}
                {currentPage === 21 && <Page21BodyComponent />}
                {currentPage === 3 && <Page3BodyComponent />}
                {/* {currentPage === 4 && <Page4BodyComponent />} */}
                {/* End Single Item */}
                {/* Pagination */}
              </div>
              {/* Start Sidebar */}
              <div className="sidebar col-md-3 sidebar_mg_top">
                <aside>
                  {userInfoObject.caId ? (
                    <div className="sidebar-item">
                      <div className="title">
                        <h4>파트너사 선택</h4>
                      </div>
                      <div className="sidebar-info" onClick={() => makeData()} >
                        <ComboBox
                          // defaultValue={'파트너사를 선택해주세요'}
                          placeholder='파트너사를 선택해주세요'
                          // defaultValue={companyName?.[0]}
                          defaultValue={userInfoObject.cId ? companyInfo.find(company => company.cId === userInfoObject.cId)?.cName : companyInfo[0]?.cName || '파트너사를 선택해주세요'}
                          // value={userInfoObject.cId ? companyInfo.find(company => company.cId === userInfoObject.cId)?.cName : companyInfo[0]?.cName || '파트너사를 선택해주세요'}
                          // value={selectedCompanyCId ? companyInfo.find(company => company.cId === selectedCompanyCId)?.cName : companyInfo[0]?.cName || '파트너사를 선택해주세요'}
                          // data={companyInfo.map(company => company.cName)}
                          data={data}
                          onChange={handleComboBoxChange}
                          filterable={true}
                          onFilterChange={filterChange}
                          // 자동완성
                          suggest={true}
                          size={'large'}
                        />
                        {/* <DropDownList
                          // value={selectedCompanyCId ? companyInfo.find(company => company.cId === selectedCompanyCId)?.cName : '파트너사를 선택해주세요'}
                          value={userInfoObject.cId ? companyInfo.find(company => company.cId === userInfoObject.cId)?.cName : companyInfo[0]?.cName || '파트너사를 선택해주세요'}
                          // value={selectedCompanyCId ? companyInfo.find(company => company.cId === selectedCompanyCId)?.cName : companyInfo[0]?.cName || '파트너사를 선택해주세요'}
                          data={companyInfo.map(company => company.cName)}
                          onChange={handleDropDownChange}
                          size={'large'}
                        /> */}
                      </div>
                    </div>
                  ) : (<div></div>)}
                  <div className="sidebar-item category">
                    <div className="title">
                      <h4>파트너 관리</h4>
                    </div>
                    <div className="sidebar-info">
                      <ul>
                        <li>
                          {/* <a href="admin_license"> */}
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(1)} className={currentPage === 1 ? 'active' : ''}>
                            대시보드
                            <span />
                          </a>
                        </li>
                        <li>
                          {/* <a href="admin_license_product_management"> */}
                          {/* <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(2)} className={currentPage === 2 ? 'active' : ''}> */}
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(2)}>
                            라이선스 관리
                          </a>
                        </li>
                        <li className="admin_license_side_bar_sub_area">
                          {/* <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(2)} className={currentPage === 2 ? 'active' : ''}> */}
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(2)}>
                            라이선스 설정
                          </a>
                        </li>
                        <li className="admin_license_side_bar_sub_area">
                          {/* <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(21)} className={currentPage === 21 ? 'active' : ''}> */}
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(21)}>
                            라이선스 조회
                          </a>
                        </li>
                        <li>
                          {/* <a href="admin_license_user_management"> */}
                          {/* <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(3)} className={currentPage === 3 ? 'active' : ''}> */}
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(3)}>
                            사용자 관리
                          </a>
                        </li>
                        {/* <li>
                          <a style={{ cursor: 'pointer' }} onClick={() => handlePageChange(4)}>
                            개인 정보 수정
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="sidebar-item recent-post">
                    <div className="title">
                      <h4>라이선스 임박 리스트</h4>
                    </div>
                    <ul>
                      {productData.map((item: Product) => (

                        <li>
                          <div className="thumb">
                            <a style={{ cursor: 'pointer' }}>
                              {/* <img src="assets/img/800x600_r3d.png" alt="Thumb" /> */}
                              {/* <img src={`${clientConfig.URL}/image/${item.pdImage}`} /> */}
                              {/* 제품이미지가 없으면 지정된 기본 이미지출력*/}
                              <img src={item.pdImage ? `${clientConfig.URL}/image/${item.pdImage}` : `${clientConfig.URL}/image/default_product_image.jpg`} />
                            </a>
                          </div>
                          <div className="info">
                            <a style={{ cursor: 'pointer' }}>{item.pdName}</a> {item.pdUse === 'N' && <a style={{ color: 'red' }}>(서비스 지원 중지)</a>}
                            {/* <a style={{ cursor: 'pointer' }}>라이선스 유형 : 동시접속</a> */}
                            <a style={{ cursor: 'pointer' }}>자격유형 :<br />{item.dsName}</a>
                            <div className="meta-title">
                              {/* <span className="post-date">{item.expiredate}</span>&nbsp;<span className="post-date">(19일 남음)</span> */}
                              <span className="post-date">{item.expiredate}</span>&nbsp;<span className="post-date">({calculateRemainingDays(item.expiredate)}일남음)</span>
                            </div>
                          </div>
                        </li>
                      ))}

                    </ul>
                  </div>
                </aside>
              </div>
              {/* End Start Sidebar */}
            </div>
          </div>
        </div>
      </div>
      {/* End License Management */}
      {/* Start Blog Area
    ============================================= */}
      {/* <!-- End Blog Area --> */}
      <Footer />
    </div>
  )
}

export default Admin_License